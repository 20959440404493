exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/servicePage.js" /* webpackChunkName: "component---src-templates-service-page-js" */),
  "component---src-templates-simple-blog-js": () => import("./../../../src/templates/simpleBlog.js" /* webpackChunkName: "component---src-templates-simple-blog-js" */),
  "component---src-templates-simple-service-page-js": () => import("./../../../src/templates/simpleServicePage.js" /* webpackChunkName: "component---src-templates-simple-service-page-js" */),
  "component---src-templates-simple-solution-page-js": () => import("./../../../src/templates/simpleSolutionPage.js" /* webpackChunkName: "component---src-templates-simple-solution-page-js" */),
  "component---src-templates-solution-page-js": () => import("./../../../src/templates/solutionPage.js" /* webpackChunkName: "component---src-templates-solution-page-js" */)
}

